@font-face {
  font-family: 'Oxygen Mono';
  src: url('assets/fonts/OxygenMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background-color: #fafafa;
  font-family: "Oxygen Mono", monospace, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  word-wrap: break-word;
}

img {
  max-width: 100%;
}

figure {
  text-align: center;
}

*::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid rgba(255, 255, 255, 0.95);
}

*::-webkit-scrollbar-button {
    display: none;
}